import { Button, Flex, HStack, Image, Menu, MenuButton, Show, Text } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import { useMe } from '@/api/auth';

import { SidebarResponsive } from '@/components/sidebar';

import { trackEvent } from '../../analytics';
import { Event } from '../../analytics/events';

import CartIndicator from './CartIndicator';

import UserMenu from './components/UserMenu';

const MENU_BUTTON_SIZE = 36;

type NavbarProps = {
  onUserMenuButtonClick: () => void;
  rightSideContent?: React.ReactNode;
};

export default function Navbar(props: NavbarProps) {
  const { onUserMenuButtonClick, rightSideContent } = props;

  const { data: me } = useMe();

  const history = useHistory();

  const cartItemsCount = me ? me.cart.items.reduce((result, item) => result + item.quantity, 0) : 0;

  const { avatarUrl, firstName, lastName } = me;

  return (
    <Flex
      bg="#FFFFFF"
      borderBottom="1px solid"
      borderColor="gray.200"
      id="ablo-dashboard-navbar"
      p={{ base: '30px 13px 16px 20px', md: '20px 32px 20px 4px' }}
      alignItems="center"
      position="relative"
      h={{ base: '70px', md: '80px' }}
      justifyContent="space-between"
      w="100%"
    >
      <SidebarResponsive me={me} />
      <Button
        _hover={{ bg: 'transparent' }}
        onClick={() => history.push('/')}
        p={0}
        variant="ghost"
      >
        <Image
          src="/AbloLogoBlack.png"
          alt="Ablo"
          width={{ base: '41px', md: '49px' }}
          position="relative"
          left={{ base: cartItemsCount ? '25px' : 0, md: 0 }}
        ></Image>
      </Button>
      <HStack w={{ base: cartItemsCount ? '87px' : '36px', md: 'auto' }}>
        {rightSideContent}
        {cartItemsCount ? <CartIndicator cartItemsCount={cartItemsCount} /> : null}
        <Menu
          onOpen={() => trackEvent(Event.AVATAR_MENU_OPENED)}
          onClose={() => trackEvent(Event.AVATAR_MENU_CLOSED)}
        >
          <MenuButton
            bg="gray.200"
            border="2px solid"
            borderColor="brand.600"
            borderRadius="50%"
            ml={{ base: 0, md: '9px' }}
            onClick={onUserMenuButtonClick}
            p="0px"
            h={`${MENU_BUTTON_SIZE}px`}
            w={`${MENU_BUTTON_SIZE}px`}
          >
            {avatarUrl ? (
              <Image borderRadius="50%" src={avatarUrl}></Image>
            ) : (
              <Text fontSize="11px" fontWeight={600}>
                {firstName?.[0] || ''}
                {lastName?.[0] || ''}
              </Text>
            )}
          </MenuButton>
          <Show above="md">
            <UserMenu />
          </Show>
        </Menu>
      </HStack>
    </Flex>
  );
}
