import { getDesign, updateBaseDesign } from '@/api/designs';

import Button from '@/components/button';

import { Design } from '@/lib';

import DesignPreviewGallery from '@/views/demo/pages/design/components/DesignPreviewGallery';

import { Box, Center, Flex, HStack, Stack, Text, useToast } from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import LoadingSpinner from '@/components/ui/LoadingSpinner';

import { Currency } from '@/components/types';

import { DESIGN_STUDIO } from '@/constants';

import AppContainer from '@/layouts/AppContainer';
import { getFormattedAmount } from '../utils/currency-formatter';
import { isCrocsTemplate } from '@/lib/utils/special-brands';
import RoyaltyModulePopover from '@/components/royalties/RoyaltyModulePopover';
import IconMoreInfo from './icons/IconMoreInfo';
import { H3 } from '@/components/typography/Headings';
import { useCurrencies } from '@/api/currencies';
import { keyBy } from 'lodash';
import Tag from '@/components/ui/tag/Tag';
import { Breadcrumbs } from '@/components/breadcrumbs';

const FIELDS = [
  {
    name: 'Price',
    getValue: (design: Design, currency: Currency) => {
      const {
        template: { price },
      } = design;

      return getFormattedAmount(currency?.name, price);
    },
  },
  {
    name: 'Earnings per sale',
    getValue: (design: Design, currency: Currency) => {
      const { template } = design;

      const { price, royaltyPercentage } = template;

      return (
        <HStack gap="5px">
          <Text textStyle="body">
            {getFormattedAmount(currency?.name, price * (royaltyPercentage / 100))}
          </Text>
          {!isCrocsTemplate(template) ? (
            <RoyaltyModulePopover
              icon={<IconMoreInfo />}
              product={{
                ...design,
                template: { ...template, currency },
              }}
            />
          ) : null}
        </HStack>
      );
    },
  },
  {
    name: 'Sales',
    getValue: (design: Design) => design.numPurchases,
  },
  {
    name: 'Design earnings',
    mobileName: 'Earnings',
    visibleOnMobile: true,
    getValue: (design: Design, currency: Currency) =>
      getFormattedAmount(currency?.name, design.totalEarnings),
  },
];

const MobileDesignPage = () => {
  const { id } = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState(true);
  const [design, setDesign] = useState<Design>(null);

  const { data: currencies } = useCurrencies();

  const currenciesById = keyBy(currencies, 'id');

  const [isChangingStatus, setChangingStatus] = useState(false);

  const history = useHistory();

  const toast = useToast();

  useEffect(() => {
    const loadDesign = async () => {
      const design = await getDesign(id);

      setDesign(design);

      setIsLoading(false);
    };

    loadDesign();
  }, [id]);

  const handleChangeStatus = async () => {
    setChangingStatus(true);

    try {
      await updateBaseDesign({
        id: design.id,
        isPublished: !design.isPublished,
      });

      toast({
        title: `Design ${design.isPublished ? 'un' : ''}published`,
        status: 'success',
      });

      setDesign({ ...design, isPublished: !design.isPublished });
    } catch {
      toast({
        title: `Error ${design.isPublished ? 'un' : ''}publishing design`,
        status: 'error',
      });
    } finally {
      setChangingStatus(false);
    }
  };

  const { isPublished, template } = design || {};

  const currency = currenciesById[template?.currencyId];

  const handleGoToIps = () => {
    history.push('/designs');
  };

  return (
    <AppContainer contentContainerProps={{ padding: 0 }}>
      <Box bg="#FFFFFF" minH="100%">
        <Box
          position="relative"
          h="calc(100dvh - 150px)"
          padding="12px 16px 0 16px"
          overflow="auto"
        >
          <Breadcrumbs crumbs={['My designs', design?.name]} handlers={[handleGoToIps]} />
          {isLoading ? (
            <Center h="100%" w="100%">
              <LoadingSpinner />
            </Center>
          ) : (
            <Stack alignItems="flex-start" direction="column" mt="13px" spacing={0}>
              <DesignPreviewGallery design={design} />
              <Flex
                align="center"
                borderBottom="1px solid"
                borderBottomColor="borderLight"
                justify="space-between"
                mt="14px"
                pb="16px"
                w="100%"
              >
                <H3>{design.name}</H3>
                <Tag
                  color={isPublished ? 'green.600' : 'secondaryDarkGray.600'}
                  backgroundColor={isPublished ? 'green.300' : '#EAEAEAB2'}
                  label={isPublished ? 'On sale' : 'Paused'}
                ></Tag>
              </Flex>
              {FIELDS.map((field) => {
                const { name, getValue } = field;

                return (
                  <Flex
                    align="center"
                    borderBottom="1px solid"
                    borderBottomColor="borderLight"
                    justify="space-between"
                    p="14px 0"
                    w="100%"
                  >
                    <Text textStyle="body">{name}</Text>
                    <Text textStyle="body">{getValue(design, currency)}</Text>
                  </Flex>
                );
              })}
              <HStack
                bg="#FFFFFF"
                bottom="16px"
                left="16px"
                right="16px"
                position="fixed"
                zIndex={2}
              >
                <Button
                  isLoading={isChangingStatus}
                  onClick={handleChangeStatus}
                  secondary={design.isPublished}
                  label="Design"
                  target={DESIGN_STUDIO}
                  w={{ base: '100%' }}
                >
                  {isPublished ? 'Stop Sale' : 'Put on Sale'}
                </Button>
              </HStack>
            </Stack>
          )}
        </Box>
      </Box>
    </AppContainer>
  );
};

export default MobileDesignPage;
