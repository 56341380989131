import { Center, Text } from '@chakra-ui/react';

interface TagProps {
  label: string;
  color: string;
  backgroundColor: string;
}

const Tag = ({ label, color, backgroundColor }: TagProps) => (
  <Center
    bg={backgroundColor}
    border="1px solid"
    borderColor={color}
    borderRadius="4px"
    p="2px 4px"
  >
    <Text color={color} textStyle="caption" textTransform="uppercase">
      {label}
    </Text>
  </Center>
);

export default Tag;
