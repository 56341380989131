import { Box, Flex, Hide, MenuItem, MenuList, Text } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import { logout, useMe } from '@/api/auth';

import IconLogout from '../icons/IconLogout';
import { H4 } from '@/components/typography/Headings';
import IconArrowRight from '../icons/IconArrowRight';

const MENU_ITEMS = [
  {
    value: 'profile',
    label: 'Profile & Settings',
  },
  {
    value: 'terms',
    label: 'Terms of Service',
  },
  {
    value: 'privacy-policy',
    label: 'Privacy Policy',
  },
];

const MenuItemStyles = {
  _hover: { bg: '#E6EDF9' },
  _focus: { bg: '#E6EDF9' },
  bg: { base: '#FFFFFF', md: 'transparent' },
  borderRadius: { base: '6px', md: '4px' },
  border: { base: '1px solid', md: 'none' },
  borderColor: '#D3DEEA',
  color: 'black.600',
  fontSize: 'sm',
  fontWeight: 600,
  lineHeight: '18px',
  p: { base: '14px', md: '11px 14px' },
  w: '100%',
};

export default function UserMenu() {
  const { data: me } = useMe();

  const history = useHistory();

  return (
    <MenuList
      p={{ base: '40px 16px 24px 16px', md: 0 }}
      borderRadius="12px"
      bg="#FFFFFF"
      border={{ base: 'none', md: '1px solid' }}
      borderColor={{ base: 'none', md: 'borderLight' }}
      boxShadow={{ base: 'none', md: '0px 4px 10px 0px rgba(192, 192, 192, 0.25)' }}
      w={{ base: '100vw', md: '100%' }}
    >
      <Flex
        borderBottom={{ base: 'none', md: '1px solid' }}
        borderColor={{ base: 'none', md: 'borderLight' }}
        mb={{ base: '20px', md: 0 }}
        w="100%"
        p={{ base: 0, md: '16px' }}
      >
        {me ? (
          <H4 fontSize="20px" fontWeight="700">
            👋&nbsp; Hey, {me.firstName}!
          </H4>
        ) : null}
      </Flex>
      <Flex
        borderBottom={{ base: 'none', md: '1px solid' }}
        borderColor={{ base: 'none', md: 'borderLight' }}
        flexDirection="column"
        gap={{ base: '12px', md: 0 }}
        p={{ base: 0, md: '8px' }}
        w="100%"
      >
        {MENU_ITEMS.map(({ value, label }) => (
          <MenuItem
            {...MenuItemStyles}
            display={{ base: 'flex', md: 'block' }}
            justifyContent="space-between"
            onClick={() => history.push(`/${value}`)}
          >
            <Text fontSize="sm">{label}</Text>
            <Hide above="md">
              <IconArrowRight />
            </Hide>
          </MenuItem>
        ))}
      </Flex>
      <Box p={{ base: '12px 0 0 0', md: '8px' }}>
        <MenuItem {...MenuItemStyles} border="none" gap="9px" p="11px 14px" onClick={logout}>
          <IconLogout />
          <Text fontWeight={600}>Log Out</Text>
        </MenuItem>
      </Box>
    </MenuList>
  );
}
