import { Icon } from '@chakra-ui/react';

const IconDetails = (props) => (
  <Icon
    width="7px"
    height="12px"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.963636 -4.25959e-07L7 6L0.963637 12L8.69938e-07 11.0422L5.07273 6L-1.16627e-08 0.957831L0.963636 -4.25959e-07Z"
      fill="#064AC4"
    />
  </Icon>
);

export default IconDetails;
