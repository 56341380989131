import { Icon } from '@chakra-ui/react';

const IconArrowRight = () => (
  <Icon
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.30458 13.3822L6.37529 13.4529L6.446 13.3822L11.7577 8.07021L11.8284 7.9995L11.7577 7.92879L6.446 2.61679L6.37529 2.54607L6.30458 2.61679L5.45663 3.46479L5.38593 3.5355L5.45663 3.60621L9.84965 7.9995L5.45663 12.3928L5.38592 12.4635L5.45663 12.5342L6.30458 13.3822Z"
      fill="#212529"
      stroke="#212529"
      strokeWidth="0.2"
    />
  </Icon>
);

export default IconArrowRight;
