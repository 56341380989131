import { Icon } from '@chakra-ui/react';

const IconLogout = () => (
  <Icon
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 6.83333L14.325 8.00833L16.475 10.1667H8V11.8333H16.475L14.325 13.9833L15.5 15.1667L19.6667 11M4.66667 5.16667H11.3333V3.5H4.66667C3.75 3.5 3 4.25 3 5.16667V16.8333C3 17.75 3.75 18.5 4.66667 18.5H11.3333V16.8333H4.66667V5.16667Z"
      fill="#212529"
    />
  </Icon>
);

export default IconLogout;
