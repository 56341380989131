import { Box, Menu } from '@chakra-ui/react';

import UserMenu from './UserMenu';

export default function UserMenuDrawer() {
  return (
    <Box
      borderRadius="24px 24px 0 0"
      bottom={0}
      bg="#FFFFFF"
      h="330px"
      position="fixed"
      zIndex={2}
      w="100%"
    >
      <Box
        bg="borderLight"
        borderRadius="4px"
        h="4px"
        left="calc(50% - 35px)"
        position="absolute"
        top="8px"
        w="69px"
        zIndex={2}
      />
      <Menu isOpen>
        <UserMenu />
      </Menu>
    </Box>
  );
}
