import { HStack, Text } from '@chakra-ui/react';
import IconChevronRight from '../icons/IconChevronRight';

type BreadcrumbsProps = {
  crumbs: string[];
  handlers: (() => void)[];
  primaryColor?: string;
  secondaryColor?: string;
  primaryFontWeight?: number;
  secondaryFontWeight?: number;
  chevronColor?: string;
};

export const Breadcrumbs = ({
  crumbs,
  handlers,
  primaryColor = 'brand.600',
  secondaryColor = 'secondaryDarkGray.600',
  primaryFontWeight = 500,
  secondaryFontWeight = 400,
  chevronColor = '#626F82',
}: BreadcrumbsProps) => {
  return (
    <HStack gap="4px">
      {crumbs.map((crumb, index) => (
        <>
          <Text
            color={index < crumbs.length - 1 ? primaryColor : secondaryColor}
            fontWeight={index < crumbs.length - 1 ? primaryFontWeight : secondaryFontWeight}
            onClick={handlers[index]}
            key={index}
            textStyle="breadcrumb"
            fontSize={{ base: '12px', md: '13px' }}
          >
            {crumb}
          </Text>
          {crumbs[index + 1] ? <IconChevronRight color={chevronColor} /> : null}
        </>
      ))}
    </HStack>
  );
};
